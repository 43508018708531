//#region Imports

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BeforeInstallPromptEvent } from '../../models/interfaces/before-install-prompt-event';
import { InstallPwaService } from '../../services/pwa/install-pwa.service';

//#endregion

@Component({
  selector: 'app-install-pwa-modal',
  templateUrl: './install-pwa-modal.component.html',
  styleUrls: ['./install-pwa-modal.component.scss'],
})
export class InstallPwaModalComponent {

  //#region Constructor

  constructor(
    private modalController: ModalController,
    private readonly pwaService: InstallPwaService,
  ) {}

  //#endregion

  //#region Public Properties

  @Input()
  public deferredPrompt!: BeforeInstallPromptEvent;

  //#endregion

  //#region Public Methods

  public async addToHomeScreen(): Promise<void> {
    await this.pwaService.addToHomeScreen().then((response: 'accepted' | 'dismissed') => {
      this.closeModal(response);
    });
  }

  public async closeModal(response: 'accepted' | 'dismissed'): Promise<void> {
    await this.modalController.dismiss(response);
  }

  //#endregion
}
