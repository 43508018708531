//#region Imports

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationService } from '../../modules/translation/services/translation/translation.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  //#region Constructor

  constructor(
    private readonly translationService: TranslationService,
  ) {}

  //#endregion

  //#region Methods

  public getErrorMessage({ status, error }: any): string {
    if (status === 503)
      return error?.message || this.translationService.translate('errors:default.unexpected');

    if (status >= 500 && status <= 599)
      return this.translationService.translate('errors:default.unexpected');

    if (!Array.isArray(error.message)) {
      if (typeof error.message === 'string' && error.message.includes('Cannot'))
        return this.translationService.translate('errors:default.not-found-route');

      return error.message || this.translationService.translate('errors:default.unexpected');
    }

    if (error.message.every(message => typeof message === 'string'))
      return error.message[0];

    return error.message.map(({ constraints }) => constraints && Object.values(constraints) || [])
      .reduce((acc, actual) => [...acc, ...actual] as string[])[0];
  }

  public isServerError({ status }: HttpErrorResponse): boolean {
    return (status >= 500 && status <= 599) || status === 0;
  }

  //#endregion

}
