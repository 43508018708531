import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InstallPwaModalComponent } from '../../components/install-pwa-modal/install-pwa-modal.component';
import { BeforeInstallPromptEvent } from '../../models/interfaces/before-install-prompt-event';
import { ModalService } from '../modals/modal.service';

@Injectable({
  providedIn: 'root',
})
export class InstallPwaService {

  constructor(
    private readonly modalService: ModalService,
  ) {}

  @HostListener('window:appinstalled')
  public onAppInstalled(): void {
    this.isDownloading.next(false);
  }

  private deferredPrompt: BehaviorSubject<BeforeInstallPromptEvent> = new BehaviorSubject<BeforeInstallPromptEvent>(null);

  private isDownloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public getDeferredPrompt$(): Observable<BeforeInstallPromptEvent> {
    return this.deferredPrompt.asObservable();
  }

  public setDeferredPrompt$(event: BeforeInstallPromptEvent): void {
    return this.deferredPrompt.next(event);
  }

  public async openInstallPwaModal(): Promise<HTMLIonModalElement> {
    const modal = await this.modalService.create({
      component: InstallPwaModalComponent,
      cssClass: 'pwa-modal',
      componentProps: {
        deferredPrompt: this.deferredPrompt,
      },
    });

    await modal.present();

    return modal;
  }

  public async addToHomeScreen(): Promise<'accepted' | 'dismissed'> {
    await this.deferredPrompt.value.prompt();
    return this.deferredPrompt.value.userChoice
      .then(async (choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          await this.deferredPrompt.next(null);
        }

        return choiceResult.outcome
      });
  }

  public getIsDownloading$(): Observable<boolean> {
    return this.isDownloading.asObservable();
  }


  public setIsDownloading$(value: boolean): void {
    return this.isDownloading.next(value);
  }
}
