//#region Imports

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, concatAll, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TokenProxy } from '../../../models/proxies/token.proxy';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { RouterService } from '../../../services/router/router.service';
import { StorageService } from '../../../services/storage/storage.service';
import { JwtPayload } from '../models/jwt.payload';

//#endregion

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  //#region Constructor

  constructor(
    private readonly alertService: AlertService,
    private readonly routerService: RouterService,
    private readonly storageService: StorageService,
    private readonly authenticationService: AuthenticationService,
  ) { }

  //#endregion

  //#region Methods

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const canPerformRequest$ = fromPromise(this.storageService.get<TokenProxy>(environment.keys.token).then(result => result && result.success || void 0)).pipe(
      map(tokenInfo => {
        if (!tokenInfo)
          return of(true);

        const jwtPayload: JwtPayload = decode(tokenInfo.token);
        const refreshJwtPayload: JwtPayload = decode(tokenInfo.refreshToken);

        const fiveMinutesInMilliseconds = 1_000 * 5;
        const maxSafeExpiresDate = +new Date() + fiveMinutesInMilliseconds;

        const expiresIn = +new Date(jwtPayload.exp * 1000);
        const shouldRefreshToken = maxSafeExpiresDate >= expiresIn;

        if (!shouldRefreshToken)
          return of(true);

        const refreshExpiresIn = +new Date(refreshJwtPayload.exp * 1000);
        const maxSafeRefreshExpiresDate = +new Date() + fiveMinutesInMilliseconds;

        const canRefreshToken = maxSafeRefreshExpiresDate >= refreshExpiresIn;

        if (!canRefreshToken)
          return of(false);

        return fromPromise(this.tryRefreshToken(tokenInfo.refreshToken));
      }),
      concatAll(),
    );

    return canPerformRequest$.pipe(
      map(canPerform => {
        if (canPerform)
          return next.handle(req);

        throw new HttpErrorResponse({ error: { message: 'A sua sessão expirou, você precisa logar novamente.' }, status: 401 });
      }),
      concatAll(),
      catchError(error => {
        if (error.status !== 401)
          throw error;

        this.storageService.get<TokenProxy>(environment.keys.token)
          .then(result => result && result.success || void 0)
          .then(shouldLogout => {
              if (!shouldLogout)
                return;

              this.authenticationService.logout()
                .then(() => this.routerService.navigateToDefaultUnProtectedRoute())
                .then(() => this.alertService.show({ title: 'Oops...', message: 'A sua sessão expirou, você precisa logar novamente.' }));
            },
          );

        throw error;
      }),
    );
  }

  //#endregion

  //#region Private Methods

  /**
   * Método que realiza a renovação do token de autenticação atual utilizando o token de atualização
   *
   * @param refreshToken O token de renovação
   */
  private async tryRefreshToken(refreshToken: string): Promise<boolean> {
    return await fetch(`${ environment.api.baseUrl }/auth/refresh`, {
      method: 'POST',
      headers: {
        Authorization: refreshToken,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    } as unknown as Request)
      .then(async result => result.ok ? ({ success: await result.json() as unknown as TokenProxy, error: undefined }) : ({ success: undefined, error: new HttpErrorResponse({ error: 'A sua sessão expirou, você precisa logar novamente.', status: 401 }) }))
      .catch(error => ({ error, success: undefined }))
      .then(async result => {
        if (result.error)
          return false;

        const { error: errorOnSaveToken } = await this.storageService.set(environment.keys.token, result.success);

        if (errorOnSaveToken)
          return false;

        return true;
      });
  }

  //#endregion

}

