import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class UpdatePwaService implements OnDestroy {
  constructor(
    private readonly updates: SwUpdate,
    private readonly alertService: AlertService,
  ) {}

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public initialize(): void {
    this.listenToUpdateLogs();
    this.checkForUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private listenToUpdateLogs(): void {
    this.updates.versionUpdates.pipe(takeUntil(this.destroy$)).subscribe(evt => {
      console.log('--PWA--');

      switch (evt.type) {
        case 'NO_NEW_VERSION_DETECTED':
          console.log(`Already at the latest version: ${ evt.version.hash }`);
          break;
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${ evt.version.hash }`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${ evt.currentVersion.hash }`);
          console.log(`New app version ready for use: ${ evt.latestVersion.hash }`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${ evt.version.hash }': ${ evt.error }`);
          break;
      }
      console.log('-------');
    });
  }

  private checkForUpdates(): void {
    const oneMinute = 60 * 1000;
    const everyThirtyMinutes$ = interval(30 * oneMinute);

    everyThirtyMinutes$.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      console.log('--PWA--');
      try {
        const updateFound = await this.updates.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');

        if (updateFound) {
          await this.alertService.show({
            title: 'Atualização disponível',
            message: 'Deseja atualizar para a última versão do aplicativo? O aplicativo será reiniciado.',
            buttons: [
              { text: 'Cancelar', role: 'cancel' },
              {
                text: 'Atualizar',
                handler: async () => document.location.reload(),
              }
            ]
          });
        }
      } catch (err) {
        console.error('Failed to check for updates:', err);
      } finally {
        console.log('-------');
      }
    });
  }
}
