export const routes = {
  apiSettings: {
    get: '/api-settings',
  },
  userMissions: {
    base: '/users-missions',
    has: '/users-missions/{missionId}/{userId}',
  },
  missions: {
    base: '/missions',
    byId: '/missions/{missionId}',
    update: '/missions/{missionId}',
    verifyQrCodeMission: '/missions/verify/qrcode/{missionId}',
    fromCampaign: '/missions/campaign/{campaignId}',
  },
  questions: {
    base: '/questions',
    byId: '/questions/{questionId}',
    update: '/questions/{questionId}',
    answer: '/questions/response/{questionId}',
  },
  campaigns: {
    base: '/campaigns',
    byId: '/campaigns/{campaignId}',
    update: '/campaigns/{campaignId}',
  },
  wheelHistory: {
    base: '/wheel/histories',
    byId: '/wheel/histories/{id}',
  },
  areas: {
    base: '/areas',
    byId: '/areas/{id}',
    withProducts: '/areas/with-products',
  },
  products: {
    base: '/products',
    raffleList: '/products/raffle/:id/list',
    raffleProduct: '/products/raffle/:id',
    raffleRetry: '/products/raffle/:id/retry',
    byId: '/products/{id}',
    purchase: '/products/purchase/{id}',
  },
  users: {
    base: '/users',
    byId: '/users/{id}',
    validateEmail: '/users/validate-email/{email}',
    getMe: '/users/me',
    update: '/users/{userId}',
  },
  authentication: {
    login: '/auth/local',
    refreshToken: '/auth/refresh',
    getMe: '/users/me',
  },
  usersProducts: {
    base: '/users-products',
    byId: '/users-products/{id}',
    byProduct: '/users-products/by-product/{id}',
  },
  userPassword: {
    forgotPassword: '/users/password/forgot/email/{email}',
    forgotClientPassword: '/users/password/forgot/client-email/{email}',
    resetPassword: '/users/password/reset/{resetPasswordCode}',
    verifyCode: '/users/password/verify/{resetPasswordCode}',
    changePassword: '/users/password/change',
  },
};
