//#region Imports

import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticateGuard } from './guards/authentication.guard';

//#endregion

export const unAuthenticatedRoute: Partial<Route> = {
  canActivate: [AuthenticateGuard],
  data: {
    routeToRedirect: environment.configuration.routeToRedirectWhenAuthenticated,
    unprotectedRoute: true,
  },
};

export const authenticatedRoute: Partial<Route> = {
  canActivate: [AuthenticateGuard],
  data: {
    routeToRedirect: environment.configuration.routeToRedirectWhenUnAuthenticated,
    protectedRoute: true,
  },
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'main',
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule), ...authenticatedRoute,
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule), ...unAuthenticatedRoute,
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), ...unAuthenticatedRoute,
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule), ...unAuthenticatedRoute,
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
