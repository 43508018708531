//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiSettingsProxy } from '../models/proxies/api-settings.proxy';
import { HttpService } from '../modules/http/services/http.service';
import { AlertService } from './alert/alert.service';
import { ErrorService } from './error/error.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class ApiSettingsService {

  constructor(
    private readonly alertService: AlertService,
    private readonly errorService: ErrorService,
    private readonly http: HttpService,
  ) {}

  //#region Private Property

  private apiSettings: BehaviorSubject<ApiSettingsProxy> = new BehaviorSubject<ApiSettingsProxy>(null);

  //#endregion

  //#region Public Property

  public async getApiSettings(): Promise<ApiSettingsProxy> {
    if (this.apiSettings.value)
      return this.apiSettings.value;
    else {
      const { success, error } = await this.http.get<ApiSettingsProxy>(environment.api.routes.apiSettings.get);

      if (success) {
        this.apiSettings.next(success);
        return this.apiSettings.value;
      } else
        if (error) {
          await this.alertService.show({
            title: 'Oops!',
            message: this.errorService.getErrorMessage(error),
          });
        }
    }
  }

  public getApiSettingsValue(): ApiSettingsProxy {
    return this.apiSettings.value;
  }

  public getApiSettings$(): Observable<ApiSettingsProxy> {
    return this.apiSettings.asObservable();
  }

  //#endregion
}
