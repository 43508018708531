//#region Imports

import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertCreateOptions } from '../../models/interfaces/alert-create-options.interface';

//#endregion

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  //#region Constructor

  constructor(
    private readonly alertController: AlertController,
  ) { }

  //#endregion

  //#region Methods

  public async show(options: AlertCreateOptions, status?: 'error' | 'success'): Promise<void> {
    if (status === 'error')
      options.title = options.title ? options.title:  'Opss...'
      if (status === 'success')
      options.title = options.title ? options.title:  'Sucesso!'

    const {
      title: header = 'Oops...',
      message,
      cssClass = 'alert',
      buttons,
    } = options;

    const alert = await this.alertController.create({
      mode: 'ios',
      header,
      message,
      cssClass,
      buttons: buttons ?? ['Ok'],
      backdropDismiss: options.backDropDismiss ?? true,
    });

    await alert.present();

    if (options.onDismiss)
      alert.onDidDismiss().then(options.onDismiss);
  }

  public async dismiss(): Promise<void> {
    await this.alertController.dismiss();
  }

  public async danger(options: AlertCreateOptions): Promise<void> {
    const {
      title: header = 'Oops!',
      message,
      cssClass = 'danger',
      buttons,
    } = options;

    const alert = await this.alertController.create({
      mode: 'ios',
      header,
      message,
      cssClass,
      buttons: buttons ?? ['Ok'],
    });

    await alert.present();

    if (options.onDismiss)
      alert.onDidDismiss().then(options.onDismiss);
  }
  //#endregion

}
