//#region Imports

import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, StatusBarInfo, Style } from '@capacitor/status-bar';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {

  //#region Public Methods

  public async initializeStatusBar(): Promise<void> {
    if (this.getPlatform() === 'web')
      return;

    if (this.getPlatform() === 'android') {
      await this.setOverlayStatusBar(true);

      try {
        await this.setAndroidSafeArea();
        await this.initializeBasicStatusBar();
      } catch (e) {
        await this.initializeBasicStatusBar();
      }

      return;
    }

    await this.initializeStatusBar();
  }

  public async initializeBasicStatusBar(): Promise<void> {
    await this.showStatusBar();
    await this.setOverlayStatusBar(true);
  }

  public async setAndroidSafeArea(): Promise<void> {
    const w: any = window;

    if (w.AndroidNotch) {
      const style = document.documentElement.style;

      w.AndroidNotch.getInsetTop(async px => {
        style.setProperty('--ion-safe-area-top', px + 'px');

        if (px === 0)
          await this.setOverlayStatusBar(true);
      }, (err) => {
        throw new Error(err);
      });
    }
  }

  public async hideStatusBar(): Promise<void> {
    if (Capacitor.isNativePlatform())
      return await StatusBar.hide();
  }

  public async showStatusBar(): Promise<void> {
    if (Capacitor.isNativePlatform())
      return await StatusBar.show();
  }

  public async setStatusBarColor(color: string, isDark: boolean = false): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await this.setStyle(isDark);

      if (Capacitor.getPlatform() === 'android')
        await StatusBar.setBackgroundColor({ color });
    }
  }

  public async setOverlayStatusBar(overlay: boolean): Promise<void> {
    if (Capacitor.isNativePlatform())
      return StatusBar.setOverlaysWebView({ overlay });
  }

  public async setStyle(isDark: boolean): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      if (isDark)
        return await StatusBar.setStyle({ style: Style.Dark });

      return await StatusBar.setStyle({ style: Style.Light });
    }
  }

  public async getInfo(): Promise<StatusBarInfo> {
    return await StatusBar.getInfo();
  }

  private getPlatform(): string {
    return Capacitor.getPlatform();
  }

  //#endregion

}
