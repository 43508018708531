import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ComponentRef, ModalOptions } from '@ionic/core';
import { StackActionService } from '../../modules/back-button/services/stack-action.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private readonly modalController: ModalController,
    private readonly router: Router,
    private readonly stackActionService: StackActionService,
  ) {}

  public async create<T extends ComponentRef = ComponentRef>(opts: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create(opts);

    this.stackActionService.addBackStackItem({
      id: 'modal-' + modal.id,
      type: 'action',
      action: async () => {
        await modal.dismiss();
      }
    });

    return modal;
  }
}
