//#region Imports

import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { AppUpdate, AppUpdateAvailability, AppUpdateInfo } from '@robingenz/capacitor-app-update';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  public async getAppUpdateInfo(): Promise<AppUpdateInfo> {
    return await AppUpdate.getAppUpdateInfo();
  }

  public async openAppStore(): Promise<void> {
    await AppUpdate.openAppStore();
  }

  public async hasUpdateAvailable(): Promise<boolean> {
    if (Capacitor.getPlatform() === 'web')
      return false;

    const appUpdateInfo = await this.getAppUpdateInfo();

    return appUpdateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
  }

  public async canPerformImmediateUpdate(): Promise<boolean> {
    const result = await AppUpdate.getAppUpdateInfo();

    return result.immediateUpdateAllowed ?? false;
  }

  public async performImmediateUpdate(): Promise<void> {
    const result = await AppUpdate.getAppUpdateInfo();

    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE)
      return;

    if (result.immediateUpdateAllowed)
      await AppUpdate.performImmediateUpdate();
  };
}
