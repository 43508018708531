//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInteractor {

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#region Methods

  public async uploadUser(entityId: number, payload: UserProxy): Promise<AsyncResult<UserProxy>> {
    const url = environment.api.routes.users.update
      .replace('{userId}', entityId.toString());
    return await this.httpService.put<UserProxy>(url, payload);
  }

  public async login(payload: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.httpService.post<TokenProxy>(environment.api.routes.authentication.login, payload);
  }

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.httpService.get<UserProxy>(environment.api.routes.authentication.getMe);
  }

  //#endregion

}
