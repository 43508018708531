import { Injectable } from '@angular/core';
import { FeatureFlag } from '../../models/enums/feature-flag.enum';
import flagsmith from 'flagsmith';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor() {
    flagsmith.init({
      environmentID: '2Up7xHB64AWoEbHwCJs3Wo',
      api: 'https://feature-flags.ligafacens.com/api/v1/',
      defaultFlags: {
        [FeatureFlag.APP_UPDADE_CHECK]: { enabled: false },
      }
    });
  }

  async isFeatureOn(featureName: string): Promise<boolean> {
    return new Promise((resolve) => {
      let retries = 1;
      const interval = setInterval(() => {
        if (flagsmith.initialised) {
          clearInterval(interval);
          resolve(flagsmith.hasFeature(featureName));
        } else {
          retries++;

          if (retries === 5) {
            // Get default value
            resolve(flagsmith.hasFeature(featureName));
          }
        }
      }, 1000);
    });
  }
}
