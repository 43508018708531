import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { TranslationConfiguration } from './models/translation-configuration';
import { I18nService } from './services/i18n/i18n.service';
import { TranslationService } from './services/translation/translation.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    TranslationService,
    I18nService,
  ],
})
export class TranslationModule {
  constructor(@Optional() @SkipSelf() existingModule?: TranslationModule) {
    if (existingModule) {
      throw new Error(
        'Translation module should be imported only by AppModule');
    }
  }

  static forRoot(configuration: TranslationConfiguration): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        { provide: TranslationConfiguration, useValue: configuration },
      ],
    };
  }
}
