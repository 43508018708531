//#region Imports

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { NotificationTypeEnum } from '../../models/enums/notification-type.enum';
import { WalletTabEnum } from '../../models/enums/wallet-tab.enum';
import { PushNotificationSchema } from '../../models/interfaces/push-notification-schema.interface';
import { AlertService } from '../alert/alert.service';
import { UserService } from '../user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  //#region Constructor

  constructor(
    private readonly ngZone: NgZone,
    private readonly userService: UserService,
    private readonly alertService: AlertService,
    private readonly routerService: Router,
  ) {}

  //#endregion

  //#region Properties

  private isRegistered: boolean = false;

  private isRegisteredToListeners: boolean = false;

  //#endregion

  //#region Methods

  public initialize(): void {
    if (this.isRegistered)
      return;

    this.isRegistered = true;

    if (!Capacitor.isNativePlatform())
      return;

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (!isPushNotificationsAvailable)
      return;

    PushNotifications.requestPermissions().then(async result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register().catch(console.error);

        PushNotifications.addListener('registration', async (token) => {
          let fcmToken = token.value;

          if (Capacitor.getPlatform() === 'ios') {
            const userToken = await FCM.getToken();

            fcmToken = userToken.token;
          }

          await this.registerFcmUserToken(fcmToken).catch(async () => {
            await this.alertService.show({
              title: 'Oops...',
              message: 'Ocorreu um erro ao registrar as notificações.',
            });
          });
        });
      } else {
        await this.alertService.show({
          title: 'Oops...',
          message: 'Não foi possível registrar as notificações, as permissões estão negadas.',
        });
      }
    }).catch(console.error);

    PushNotifications.addListener('registrationError', async () => {
      await this.ngZone.run(async () => {
        await this.alertService.show({
          title: 'Oops...',
          message: 'Ocorreu um erro ao registrar as notificações.',
        });
      });
    });
  }

  public initializeListeners(): void {
    if (this.isRegisteredToListeners)
      return;

    this.isRegisteredToListeners = true;

    if (!Capacitor.isNativePlatform())
      return;

    PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      await this.ngZone.run(async () => {
        if (Capacitor.getPlatform() === 'android') {
          await this.alertService.show({
            title: notification.title || 'Troca realizada!',
            message: notification.body || 'Um de seus produtos acaba de ser resgatado com sucesso!',
            buttons: [
              {
                text: 'Fechar', role: 'cancel',
              },
              notification?.data?.notificationType === NotificationTypeEnum.RECEIVE_PRODUCT && {
                text: 'Ver extrato',
                handler: async () => await this.routerService.navigateByUrl(`/main/wallet?tab=${ WalletTabEnum.HISTORY }`),
              },
            ],
          }, 'success');
        }
      });
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      await this.ngZone.run(this.doNotificationAction.bind(this, notification));
    });
  }

  private async registerFcmUserToken(token: string): Promise<void> {
    const isShortSession = !await this.userService.shouldKeepConnected();

    if (isShortSession)
      return;

    const user = await this.userService.getUser();

    if (!user || !Capacitor.isNativePlatform())
      return;

    await this.userService.update(user.id, { fcmToken: token });
  }

  public async cleanFcmUserToken(): Promise<void> {
    const user = await this.userService.getUser();

    this.isRegistered = false;

    if (!user || !Capacitor.isNativePlatform())
      return;

    await PushNotifications.removeAllDeliveredNotifications().catch(console.error);

    await FCM.deleteInstance().catch(console.error);
    await this.userService.update(user.id, { fcmToken: null }).catch((error) => {
      console.error('It was not possible to call user fcmToken clear route');
      console.error(error);
    });
  }

  private async doNotificationAction({ notification }: PushNotificationSchema): Promise<void> {
    await this.routerService.navigateByUrl(`/main/wallet?tab=${ WalletTabEnum.HISTORY }`);
  }

  //#endregion

}
