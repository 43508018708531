//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { TokenProxy } from '../models/proxies/token.proxy';
import { StorageService } from '../services/storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticateGuard implements CanActivate {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly storage: StorageService,
  ) { }

  //#endregion

  //#region Public Methods

  public async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot) {
    const { unprotectedRoute, protectedRoute, routeToRedirect } = route.data || {};

    if (!routeToRedirect)
      return true;

    const token = await this.storage.get<TokenProxy>(environment.keys.token).then(result => result.success);

    if (token && protectedRoute)
      return true;

    if (!token && unprotectedRoute)
      return true;
    return void await this.router.navigate([routeToRedirect]);
  }

  //#endregion
}
