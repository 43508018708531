//#region Imports

import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiSettingsProxy } from '../../models/proxies/api-settings.proxy';
import { convertValueToDate } from '../../utils/convertValueToDate';
import { ApiSettingsService } from '../api-settings.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class CustomValidatorsService {

  constructor(
    private readonly apiSettingsService: ApiSettingsService,
  ) {
    this.apiSettingsService.getApiSettings$().pipe(takeUntil(this.destroy$)).subscribe(settings => {
      this.apiSettings = settings;
    });
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public apiSettings: ApiSettingsProxy;

  public async ngOnDestroy(): Promise<void> {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public mustMatch(controlName: string, matchingControlName: string): (formGroup: FormGroup) => ValidationErrors | null {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl?.errors && !matchingControl?.errors.mustMatch)
        return null;

      if (control?.value !== matchingControl?.value)
        matchingControl?.setErrors({ mustMatch: true });
      else
        matchingControl?.setErrors(null);

      return null;
    };
  }

  public userName = (formValue: AbstractControl): ValidationErrors => {
    const value = formValue.value;
    const isValid = this.isValidUserName(value);

    if (!isValid)
      return { userName: true };

    return null;
  };

  public password = (formValue: AbstractControl): ValidationErrors => {
    const value = formValue.value;

    const isValid =
      this.verifyUppercaseLetters(value) &&
      this.verifyLowercaseLetters(value) &&
      this.verifySpecialCharacters(value) &&
      this.verifyPasswordLength(value) &&
      this.verifyNumbers(value) &&
      this.verifyLetters(value);

    if (!isValid)
      return { password: true };

    return null;
  };

  public cpf = (formValue: AbstractControl): ValidationErrors => {
    const value = formValue.value;
    const isValid = this.isValidCpf(value);

    if (!isValid)
      return { cpf: true };

    return null;
  };

  public isValidDate = (formValue: AbstractControl): ValidationErrors => {
    const date = formValue.value;

    if (!date || !this.isValidBirthdate(convertValueToDate(date)))
      return { birthday: true };

    return null;
  }

  public minAge(formValue: AbstractControl): ValidationErrors {
    const date = convertValueToDate(formValue.value);

    const hasMinAge = (new Date().getFullYear() - new Date(date).getFullYear()) > 6;

    if (!hasMinAge)
      return { birthday: true };

    return null;
  }

  public isValidEmail(email: string): boolean {
    const regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
    return regex.test(email);
  }

  public isValidPassword(password: string): boolean {
    const regex = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$');

    return regex.test(password);
  }

  public isValidUserName = (value: string): boolean => {
    return this.isValidContent(value, 1) && !this.hasNumber(value) && !this.hasSpecialCharacter(value);
  };

  public isValidPhone = (value: string): boolean => {
    return this.isValidContent(value, 10);
  };

  public isValidBirthdate(date: Date): boolean {
    const currentDate = new Date();
    const minDate = new Date();

    minDate.setFullYear(currentDate.getFullYear() - 100);

    return date > minDate && date < currentDate;
  }

  public isValidContent(content: string, length?: number): boolean {
    return content && content !== '' && ((length && content.trim().length >= length) || !length);
  }

  public hasNumber(value: string): boolean {
    const regex = new RegExp('([0-9])');
    return regex.test(value);
  }

  public hasSpecialCharacter(value: string): boolean {
    const regex = new RegExp('[!@#$&]');
    return regex.test(value);
  }

  public isValidCpf(value: string): boolean {
    const BLACKLIST: Array<string> = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
      '12345678909',
    ];

    const STRICT_STRIP_REGEX = /[.-]/g;
    const LOOSE_STRIP_REGEX = /[^\d]/g;

    const verifierDigit = (digits: string): number => {
      const numbers: Array<number> = digits
        .split('')
        .map(num => {
          return parseInt(num, 10);
        });

      const modulus = numbers.length + 1;
      const multiplied: Array<number> = numbers.map((num, index) => num * (modulus - index));
      const mod: number = multiplied.reduce((buffer, num) => buffer + num) % 11;

      return (mod < 2 ? 0 : 11 - mod);
    };

    const strip = (num: string, strict?: boolean): string => {
      const regex: RegExp = strict ? STRICT_STRIP_REGEX : LOOSE_STRIP_REGEX;
      return (num || '').replace(regex, '');
    };

    const isValid = (num: string, strict?: boolean): boolean => {
      const stripped: string = strip(num, strict);

      // CPF must be defined
      if (!stripped) {
        return false;
      }

      // CPF must have 11 chars
      if (stripped.length !== 11) {
        return false;
      }

      // CPF can't be blacklisted
      if (BLACKLIST.includes(stripped)) {
        return false;
      }

      let numbers: string = stripped.substr(0, 9);
      numbers += verifierDigit(numbers);
      numbers += verifierDigit(numbers);

      return numbers.substr(-2) === stripped.substr(-2);
    };

    return isValid(value);
  }

  public verifyPasswordLength = (password: string): boolean => {
    if (this.apiSettings && !this.apiSettings.passwordValidations.minLength)
      return true;

    return password.length >= this.apiSettings.passwordValidations.minLength;
  }

  public verifyNumbers = (password: string): boolean => {
    if (this.apiSettings && !this.apiSettings.passwordValidations.hasNumber)
      return true;

    const regexNumbers = /[0-9]/;

    return regexNumbers.test(password);
  }

  public verifyLowercaseLetters = (password: string): boolean => {
    if (this.apiSettings && !this.apiSettings.passwordValidations.hasLowercaseLetter)
      return true;

    const regexUppercaseLetters = /([a-z])/;

    return regexUppercaseLetters.test(password);
  }

  public verifyLetters = (password: string): boolean => {
    if (this.apiSettings && !this.apiSettings.passwordValidations.hasLetter)
      return true;

    const regexUppercaseLetters = /[a-zA-Z]/;

    return regexUppercaseLetters.test(password);
  }

  public verifyUppercaseLetters = (password: string): boolean => {
    if (this.apiSettings && !this.apiSettings.passwordValidations.hasUppercaseLetter)
      return true;

    const regexUppercaseLetters = /([A-Z])/;

    return regexUppercaseLetters.test(password);
  }

  public verifySpecialCharacters = (password: string): boolean => {
    if (this.apiSettings && !this.apiSettings.passwordValidations.hasSpecialCharacter)
      return true;

    const regexSpecialCharacters = new RegExp(/[^a-zA-Z0-9]/);

    return regexSpecialCharacters.test(password);
  }

  public hasMinAge(birthday: Date): boolean {
    return (new Date().getFullYear() - new Date(birthday).getFullYear()) > 6;
  }

  public isUnderage(birthday: Date): boolean {
    return (new Date().getFullYear() - new Date(birthday).getFullYear()) < 18;
  }
}
