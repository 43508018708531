import { Animation, createAnimation } from '@ionic/angular';
import { IonicRouterAnimationOptions } from '../models/interfaces/ionic-router-animation-options.interface';

export const routerAnimation = (_: HTMLElement, options: IonicRouterAnimationOptions): Animation => {
  const enteringAnimation = createAnimation()
    .addElement(options.enteringEl)
    .duration( options.duration || 150)
    .easing('ease-in')
    .fromTo('opacity', '0', '1');

  const leavingAnimation = createAnimation()
    .addElement(options.leavingEl)
    .duration( options.duration || 150)
    .delay(50)
    .easing('ease-out')
    .fromTo('opacity', '1', '0');

  if (options.direction === 'forward')
    return enteringAnimation;

  return createAnimation().addAnimation([enteringAnimation, leavingAnimation]);
};
