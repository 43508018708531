import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {

  //#region Properties

  private readonly currentScreenNameSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly shouldSendCloseEventSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  //#endregion

  //#region Getters and Setters

  public getCurrentScreenName(): string {
    return this.currentScreenNameSubject.value;
  }

  public setCurrentScreenName(screenName: string): void {
    return this.currentScreenNameSubject.next(screenName);
  }

  public checkIfShouldSendCloseEvent(): boolean {
    return this.shouldSendCloseEventSubject.value;
  }

  public setShouldSendCloseEvent(shouldSend: boolean): void {
    return this.shouldSendCloseEventSubject.next(shouldSend);
  }

  //#endregion

  //#region Methods

  /**
   * Platform: Web/Android/iOS
   * Sets the user ID property.
   * @param userId - unique identifier of a user
   * https://firebase.google.com/docs/analytics/userid
   */
  public async setUserId(userId: string): Promise<void> {
    await FirebaseAnalytics.setUserId({ userId });
  }

  /**
   * Platform: Web/Android/iOS
   * Sets a user property to a given value.
   * @param key - property name
   * @param value - value to set
   * https://firebase.google.com/docs/analytics/user-properties
   */
  public async setUserProperty(key: string, value: string): Promise<void> {
    await FirebaseAnalytics.setUserProperty({ key, value });
  }

   /**
   * Platform: Android/iOS
   * Sets the current screen name, which specifies the current visual context in your app.
   * @param screenName - name of the current screen to track
   * @param  screenClassOverride - name of the screen class to override
   * https://firebase.google.com/docs/analytics/screenviews
   */
  public async setScreenName(screenName: string, screenClassOverride: string): Promise<void> {
    this.setCurrentScreenName(screenName);

    if (Capacitor.getPlatform() !== 'web')
      return await FirebaseAnalytics.setCurrentScreen({ screenName, screenClassOverride });
  }

  /**
   * Platform: Web/Android/iOS
   * Clears all analytics data for this app from the device and resets the app instance id.
   */
  public async resetAnalyticsData(): Promise<void> {
    await FirebaseAnalytics.resetAnalyticsData();
  }

  /**
   * Platform: Web/Android/iOS
   * Logs an app event.
   * @param name - name of the event to log
   * @param params - key/value pairs of properties (25 maximum per event)
   * @returns void
   */
  public async logEvent(name: string, params: object): Promise<void> {
    await FirebaseAnalytics.logEvent({ name, params });
  }

  /**
   * Platform: Web/Android/iOS
   * Sets whether analytics collection is enabled for this app on this device.
   * @param enabled - enabled - boolean true/false
   */
  public async setEnabled(enabled: boolean): Promise<void> {
    await FirebaseAnalytics.setEnabled({ enabled });
  }

  public async isEnabled(): Promise<boolean> {
    const { enabled } = await FirebaseAnalytics.isEnabled();
    return enabled; 
  }

  /**
   * Platform: Web/Android/iOS
   * Sets the duration of inactivity that terminates the current session.
   * @param durationInSeconds - duration in seconds (default - 18000)
   */
  public async setSessionTimeoutDuration(durationInSeconds: number): Promise<void> {
    await FirebaseAnalytics.setSessionTimeoutDuration({ duration: durationInSeconds });
  }

  //#endregion
}
