import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LottieModule } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { routerAnimation } from './animations/router-animation';
import languageDetector from 'i18next-browser-languagedetector';
import XhrBackend from 'i18next-http-backend';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { InstallPwaModalModule } from './components/install-pwa-modal/install-pwa-modal.module';
import { StorageDrivers } from './models/enums/storage-drivers.enum';
import { BackButtonModule } from './modules/back-button/back-button.module';
import { HttpModule } from './modules/http/http.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BaseUrlInterceptor } from './modules/http/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http/interceptors/bearer-token.interceptor';
import { LanguageInterceptor } from './modules/http/interceptors/language.interceptor';
import { RefreshTokenInterceptor } from './modules/http/interceptors/refresh-token.interceptor';
import { RetryInterceptor } from './modules/http/interceptors/retry.interceptor';
import { LanguagesEnum, supportedLanguages } from './modules/translation/models/languages.enum';
import { TranslationConfiguration } from './modules/translation/models/translation-configuration';
import { TranslationModule } from './modules/translation/translation.module';
import { NgxMaskModule } from 'ngx-mask';
import player from 'lottie-web';

const translationPlugins = [languageDetector, XhrBackend];
export const translationConfiguration: TranslationConfiguration = {
  use: translationPlugins,
  config: {
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    fallbackLng: LanguagesEnum.PORTUGUESE_BR,
    supportedLngs: supportedLanguages,
    cleanCode: true, // Uses lowercase to represent languages and uppercase to represent locale. Ex: en-US and pt-BR
    missingKeyNoValueFallbackToKey: true,
    returnEmptyString: true,
    ns: ['pages', 'components', 'models', 'modals', 'alerts', 'errors', 'services'], // File Namespaces
    backend: {
      loadPath: 'assets/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      skipOnVariables: false,
    }
  },
};

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    InstallPwaModalModule,
    LottieModule.forRoot({ player: playerFactory }),
    BrowserModule,
    NgxMaskModule.forRoot(),
    IonicModule.forRoot({
      navAnimation: routerAnimation,
      animated: true,
    }),
    IonicStorageModule.forRoot({
      dbKey: '__clubdb_key',
      storeName: '__clubdb',
      driverOrder: [
        StorageDrivers.SQLITE,
        StorageDrivers.IndexedDB,
        StorageDrivers.LocalStorage,
      ],
    }),
    HttpModule.withConfig({
      baseUrl: environment.api.baseUrl,
      bearerTokenKey: environment.keys.token,
      languageParamName: 'lang',
    }),
    TranslationModule.forRoot(translationConfiguration),
    AppRoutingModule,
    BackButtonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.pwa })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
