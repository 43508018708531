//#region Imports

import { Injectable } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {

  //#region Public Methods

  public async hide(): Promise<void> {
    await SplashScreen.hide();
  }

  //#endregion

}
