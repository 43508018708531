//#region Imports

import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { appAssets } from 'src/theme/assets/app-assets';
import { FeatureFlag } from './models/enums/feature-flag.enum';
import { BeforeInstallPromptEvent } from './models/interfaces/before-install-prompt-event';
import { UserProxy } from './models/proxies/user.proxy';
import { BackButtonService } from './modules/back-button/services/back-button.service';
import { AlertService } from './services/alert/alert.service';
import { ApiSettingsService } from './services/api-settings.service';
import { AppUpdateService } from './services/app-update/app-update.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { FeatureFlagService } from './services/feature-flag/feature-flag.service';
import { FirebaseAnalyticsService } from './services/firebase/firebase-analytics.service';
import { PushNotificationService } from './services/push-notification/push-notification.service';
import { InstallPwaService } from './services/pwa/install-pwa.service';
import { UpdatePwaService } from './services/pwa/update-pwa.service';
import { SplashScreenService } from './services/splash-screen/splash-screen.service';
import { StatusBarService } from './services/status-bar/status-bar.service';
import { UserService } from './services/user/user.service';
import { isMobileWeb } from './utils/functions';

//#endregion

interface LayoutVariables {
  desktopBackground?: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

//#region Constructor

export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private readonly userService: UserService,
    private readonly alertService: AlertService,
    private readonly statusBarService: StatusBarService,
    private readonly backButtonService: BackButtonService,
    private readonly splashScreenService: SplashScreenService,
    private readonly authenticationService: AuthenticationService,
    private readonly appUpdateService: AppUpdateService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly installPwaService: InstallPwaService,
    private readonly updatePwaService: UpdatePwaService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly firebaseAnalyticsService: FirebaseAnalyticsService,
    private readonly ngZone: NgZone,
    private readonly apiSettingsService: ApiSettingsService,
  ) {
    this.userSubscription = this.userService.getUser$()?.subscribe(this.initializeUser.bind(this));
    this.pushNotificationService.initializeListeners();

    this.checkKeepConnected().then(async () => {
      await this.splashScreenService.hide();
    });
  }

  //#endregion

  //#region Properties

  public layoutVariables: LayoutVariables = {
    desktopBackground: appAssets.backgrounds.desktopBackground,
  }

  private userSubscription: Subscription = new Subscription();

  //#endregion

  //#region Public Methods

  @HostListener('window:beforeinstallprompt', ['$event'])
  public async onbeforeinstallprompt(event: BeforeInstallPromptEvent): Promise<void> {
    if (!isMobileWeb() || window.location.href.includes('localhost'))
      return;

    this.installPwaService.setDeferredPrompt$(event);
    await this.installPwaService.openInstallPwaModal();
  }

  public async ngOnInit(): Promise<void> {
    if (environment.pwa)
      this.updatePwaService.initialize();

    await this.apiSettingsService.getApiSettings();

    await Promise.all([
      this.initializeStatusBar(),
      this.initializeBackButton(),
      this.setUpFirebaseAnalytics(),
    ]).catch(console.error);

    await this.checkAppUpdate();
  }

  public async ngOnDestroy(): Promise<void> {
    this.userSubscription?.unsubscribe();
  }

  //#endregion

  //#region Private Methods

  private initializeUser(user?: UserProxy): void {
    if (!user)
      return;

    this.pushNotificationService.initialize();
  }

  private async initializeBackButton(): Promise<void> {
    await this.backButtonService.initializeBackButton();
  }

  private async initializeStatusBar(): Promise<void> {
    await this.statusBarService.initializeStatusBar();
  }

  private async checkKeepConnected(): Promise<void> {
    const isLogged = await this.userService.getUser().then(result => !!result);
    const isShortSession = await this.userService.shouldKeepConnected().then(result => !result);

    if (isLogged && isShortSession)
      await this.authenticationService.logout();
  }

  private async setUpFirebaseAnalytics(): Promise<void> {
    if (Capacitor.getPlatform() === 'web')
      return;

    await this.firebaseAnalyticsService.setSessionTimeoutDuration(3600);

    App.addListener('appStateChange', async ({ isActive }) => {
      if (isActive || !this.firebaseAnalyticsService.checkIfShouldSendCloseEvent())
        return;

      await this.ngZone.run(async () => {
        await this.firebaseAnalyticsService.logEvent(
          environment.firebaseEvents.appClose,
          { lastScreenName: this.firebaseAnalyticsService.getCurrentScreenName() });

        this.firebaseAnalyticsService.setShouldSendCloseEvent(false);
        setTimeout(() => this.firebaseAnalyticsService.setShouldSendCloseEvent(true), 5000);
      });
    });
  }

  private async checkAppUpdate(): Promise<void> {
    if (Capacitor.getPlatform() === 'web')
      return;

    const isAppUpdateFeatureOn = await this.featureFlagService.isFeatureOn(FeatureFlag.APP_UPDADE_CHECK);

    if (!isAppUpdateFeatureOn)
      return;

    const hasUpdateAvailable = await this.appUpdateService.hasUpdateAvailable();

    if (!hasUpdateAvailable)
      return;

    const canPerformImmediateUpdate = await this.appUpdateService.canPerformImmediateUpdate();

    if (canPerformImmediateUpdate) {
      await this.appUpdateService.performImmediateUpdate();

      const stillNeedsUpdate = await this.appUpdateService.hasUpdateAvailable();

      if (!stillNeedsUpdate)
        return;
    }

    await this.alertService.show({
      title: 'Atualização disponível!',
      message: 'Por favor, atualize o aplicativo.',
      buttons: [
        {
          text: 'Ir para a loja',
          handler: async () => await this.appUpdateService.openAppStore(),
        },
      ],
      backDropDismiss: false,
    });
  }

  //#endregion

}
