import { NgModule } from '@angular/core';
import { ModalConfirmModule } from '../../modals/modal-confirm/modal-confirm.module';

import { BackButtonDirective } from './directives/back-button.directive';
import { BackButtonService } from './services/back-button.service';

@NgModule({
  imports: [
    ModalConfirmModule,
  ],
  exports: [
    BackButtonDirective,
  ],
  declarations: [
    BackButtonDirective,
  ],
  providers: [
    BackButtonService,
  ],
})
export class BackButtonModule { }
