//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslationService } from '../../translation/services/translation/translation.service';

import { HttpAsyncConfig } from '../models/http-async.config';
import { HTTP_ASYNC_CONFIG } from '../models/injection-tokens';

//#endregion

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  //#region Constructor

  constructor(
    private readonly translationService: TranslationService,
    @Inject(HTTP_ASYNC_CONFIG)
    @Optional()
    protected readonly config?: HttpAsyncConfig,
  ) { }

  //#endregion

  //#region Properties

  public static readonly DISABLE_HEADER: string = 'X-Disabled-HttpAsyncHeaders';

  //#endregion

  //#region Methods

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.config?.languageParamName) {
      console.warn('Você incluiu o Interceptor para lidar com o parâmetro de linguagem, mas não indicou o nome para o mesmo no módulo.');

      return next.handle(req);
    }

    if (!req.headers.get(LanguageInterceptor.DISABLE_HEADER)) {
      const updatedParams = req.params.set(this.config.languageParamName, this.translationService.getCurrentLanguage());

      req = req.clone({
        params: updatedParams,
      });

      return next.handle(req);
    }

    req = req.clone({
      headers: req.headers.delete(LanguageInterceptor.DISABLE_HEADER),
    });

    return next.handle(req);
  }

  //#endregion

}
