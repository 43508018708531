//#region Imports

import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavbarEnum } from '../../../models/enums/navbar.enum';

//#endregion

const STACK_MAX_SIZE = 30;

export type BackStackItemType = 'action' | 'url';

export type BackStackItem = {
  id?: string;
  type: BackStackItemType;
  url?: string;
  action?: () => Promise<void>;
};

@Injectable({
  providedIn: 'root',
})
export class StackActionService {

  //#region Constructor

  constructor(
    private readonly router: Router,
  ) {}

  //#endregion

  //#region Private Properties

  public backStackItems: BackStackItem[] = [];

  public blockToAddUrl: boolean = false;

  private lastUrl: string = '';

  //#endregion

  //#region Public Methods

  public initializeActions(): void {
    this.addRouterSubscriptionToStackUrlItems();
  }

  public addBackStackItem(backStackItem: BackStackItem): void {
    this.backStackItems.push(backStackItem);

    if (this.backStackItems.length > STACK_MAX_SIZE)
      this.backStackItems.shift();
  }

  public popBackStackItem(): BackStackItem {
    return this.backStackItems.pop();
  }

  public clearBackStackItems() {
    this.backStackItems = [];
  }

  //#endregion

  //#region Private Methods

  private addRouterSubscriptionToStackUrlItems(): void {
    this.router.events.subscribe((event) => {

      if (event instanceof NavigationStart)
        this.lastUrl = this.router.url;

      if (event instanceof NavigationEnd) {
        if (this.isNavigatingToAnotherMainPage(this.router.url))
          return;

        const stackUrlItemToAdd: BackStackItem = {
          url: this.lastUrl,
          type: 'url',
        };

        if (!this.blockToAddUrl && stackUrlItemToAdd.url !== '/'){
          this.addBackStackItem(stackUrlItemToAdd);
        }
      }
    });
  }

  private isNavigatingToAnotherMainPage(newUrl: string): boolean {
    return this.isMainPage(this.lastUrl) && this.isMainPage(newUrl);
  }

  private isMainPage(url: string): boolean {
    const urlWithoutQueryparams = url.split('?')[0];
    const urlHasNestedPage = !!urlWithoutQueryparams.split('/')[3];
    if (urlHasNestedPage)
      return false;

    const page = urlWithoutQueryparams.split('/')[2];

    return Object.values(NavbarEnum).includes(page as NavbarEnum);
  }

  //#endregion

}
