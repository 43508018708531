//#region Imports

import { Injectable } from '@angular/core';
import { TOptionsBase } from 'i18next';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../services/storage/storage.service';
import { LanguagesEnum } from '../../models/languages.enum';
import { TranslationConfiguration } from '../../models/translation-configuration';
import { I18nService } from '../i18n/i18n.service';

//#endregion

@Injectable()
export class TranslationService {

  //#region Constructor

  constructor(
    private translationConfiguration: TranslationConfiguration,
    private storageService: StorageService,
    private i18nService: I18nService,
  ) {
    this.i18nService.initializeInstance(this.onInitializeCallback).catch(console.error);
  }

  //#endregion

  //#region Properties

  private isInitialized: boolean = false;

  public languageChangeEvents: BehaviorSubject<LanguagesEnum> = new BehaviorSubject<LanguagesEnum>(LanguagesEnum.NONE);

  //#endregion

  //#region Methods

  public translateWithPromise(translationCode: string, options?: TOptionsBase): Promise<string> {
    return new Promise<string>(((resolve, reject) => {
      if (this.isInitialized)
        resolve(this.translate(translationCode, options));
      else {
        const timeout = setTimeout(() => {
          reject(this.translate('errors:translation-timeout'));
        }, 5000);

        const initializeHandler = () => {
          clearTimeout(timeout);
          this.i18nService.subscribeEvent('initialized', initializeHandler);

          resolve(this.translate(translationCode, options));
        };

        this.i18nService.unsubscribeEvent('initialized', initializeHandler);
      }
    }));
  }

  public onInitializeCallback(): void {
    this.isInitialized = true;

    if (this.i18nService.isSupportedLanguage(this.i18nService.getLanguage()))
      this.languageChangeEvents.next(this.i18nService.i18nInstance.getValue().language as LanguagesEnum);

    this.i18nService.subscribeEvent('languageChanged', async (language: string) => {
      this.languageChangeEvents.next(language as LanguagesEnum);
      this.storageService.set(environment.keys.language, language).catch(console.error);
    });
  }

  public async forceChangeLanguage(language: LanguagesEnum): Promise<void> {
    await this.i18nService.changeLanguage(language);
  }

  public async changeLanguage(language: LanguagesEnum): Promise<void> {
    if (!this.i18nService.isSupportedLanguage(language))
      throw new Error(this.translate('errors:unsupported-language'));

    if (this.isInitialized)
      return void await this.i18nService.changeLanguage(language);

    const timeout = setTimeout(() => {
      throw new Error(this.translate('errors:translation-timeout'));
    }, 5000);

    const initializeHandler = async () => {
      clearTimeout(timeout);
      this.i18nService.unsubscribeEvent('initialized', initializeHandler);

      return void await this.i18nService.changeLanguage(language);
    };

    this.i18nService.subscribeEvent('initialized', initializeHandler);
  }

  public getCurrentLanguage(): string {
    return this.i18nService.getLanguage();
  }

  public translate(translationCode: string, options?: TOptionsBase): string {
    return this.i18nService.translate(translationCode, options);
  }

  //#endregion
}
