//#region Imports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { AuthenticationInteractor } from '../../interactors/authentication/authentication.interactor';
import { RolesEnum } from '../../models/enums/roles.enum';
import { LoginPayload } from '../../models/payloads/login.payload';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { TranslationService } from '../../modules/translation/services/translation/translation.service';
import { AlertService } from '../alert/alert.service';
import { ErrorService } from '../error/error.service';
import { PushNotificationService } from '../push-notification/push-notification.service';
import { StorageService } from '../storage/storage.service';
import { UserService } from '../user/user.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  //#region Constructor

  constructor(
    private readonly userService: UserService,
    private readonly errorService: ErrorService,
    private readonly storageService: StorageService,
    private readonly interactor: AuthenticationInteractor,
    private readonly translationService: TranslationService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly alertService: AlertService,
    private readonly router: Router
  ) {}

  //#endregion

  //#region Methods

  public async getUserFromStorage(): Promise<UserProxy | null> {
    const { success, error } = await this.storageService.get<UserProxy>(
      environment.keys.userInformation
    );

    if (error)
      throw new Error(
        error.message ||
          this.translationService.translate('errors:user-from-storage')
      );

    return success;
  }

  public async getMyProfile(): Promise<[boolean, string]> {
    const { error: errorUser, success: user } = await this.interactor.getMe();

    if (errorUser) {
      await this.storageService.clear();

      return [false, this.errorService.getErrorMessage(errorUser)];
    }

    const { error: errorOnSaveUser } = await this.storageService.set(
      environment.keys.userInformation,
      user
    );

    if (errorOnSaveUser) {
      await this.storageService.clear();

      return [false, errorOnSaveUser.message];
    }

    await this.userService.setUser$(user);

    return [true, `${user.name} atualizado.`];
  }

  public async updateUser(
    entityId: number,
    payload: UserProxy
  ): Promise<[boolean, string]> {
    const { error } = await this.interactor.uploadUser(entityId, payload);

    if (error) return [false, this.errorService.getErrorMessage(error)];

    this.getMyProfile().then();

    return [true, 'Usuário atualizado com sucesso.'];
  }

  public async getMe(): Promise<UserProxy> {
    const { success, error } = await this.interactor.getMe();
    if (error) {
      return;
    }
    return success;
  }

  public async getUserTokenFromStorage(): Promise<TokenProxy | null> {
    const { error, success } = await this.storageService.get<TokenProxy>(
      environment.keys.token
    );

    if (error)
      throw new Error(
        error.message ||
          this.translationService.translate('errors:user-token-from-storage')
      );

    return success;
  }

  public isLogged(): boolean {
    return !!this.userService.getUser();
  }

  public async logout(shouldRedirect: boolean = true): Promise<void> {
    await this.pushNotificationService.cleanFcmUserToken().catch(() => null);
    await this.storageService.clear();
    this.userService.clearUser();

    if(shouldRedirect)
      return void (await this.router.navigateByUrl(
        environment.configuration.routeToRedirectWhenUnAuthenticated
      ));
  }

  public async login(payload: LoginPayload): Promise<UserProxy> {
    const { error, success: tokenProxy } = await this.interactor.login(payload);

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));

    await this.storageService.set(environment.keys.keepConnected, payload.keepConnected);

    await this.saveUserToken(tokenProxy);
    await this.getUserInformationAndUpdateLoggedUser();

    const user = await this.userService.getUser();

    if (!user.roles.includes(RolesEnum.CLIENT)) {
      await this.logout(false);

      return void (await this.alertService.show({
        title: 'Oops!',
        message: 'Somente clientes podem acessar a plataforma.',
      }));
    }

    return user;
  }

  private async saveUserToken(token: TokenProxy): Promise<void> {
    const { error: errorOnSaveToken } = await this.storageService.set(
      environment.keys.token,
      token
    );

    if (errorOnSaveToken) throw new Error(errorOnSaveToken.message);

  }

  public async getUserInformationAndUpdateLoggedUser(): Promise<void> {
    const { error: errorUser, success: user } = await this.interactor.getMe();

    if (errorUser) {
      await this.storageService.clear();
      throw new Error(this.errorService.getErrorMessage(errorUser));
    }

    try {
      await this.updateLoggedUser(user);
    } catch (error) {
      throw new Error(error);
    }
  }

  public async updateLoggedUser(user: UserProxy): Promise<void> {
    const { error: errorOnSaveUser } = await this.storageService.set(
      environment.keys.userInformation,
      user
    );

    if (errorOnSaveUser) {
      await this.storageService.clear();
      throw new Error(
        this.errorService.getErrorMessage(errorOnSaveUser.message)
      );
    }

    this.userService.setUser(user);
  }

  //#endregion
}
