//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { environment } from '../../../environments/environment';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class RouterService {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly navController: NavController,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  //#endregion

  //#region Methods

  public async navigateForward(url: string, options?: NavigationOptions): Promise<void> {
    await this.navController.navigateForward(url, options);
  }

  public async navigateBackwards(url: string, options?: NavigationOptions): Promise<void> {
    await this.navController.navigateBack(url, options);
  }

  public async navigateRoot(url: string): Promise<void> {
    await this.navController.navigateRoot(url, { replaceUrl: false, animated: false, skipLocationChange: false, preserveFragment: false });
  }

  public async navigateByNativeRouter(url: string): Promise<void> {
    await this.router.navigateByUrl(url, { replaceUrl: true });
  }

  public async navigateBack(): Promise<void> {
    await this.navController.back();
  }

  public async navigateToDefaultProtectedRoute(): Promise<void> {
    await this.navController.navigateForward(environment.configuration.routeToRedirectWhenAuthenticated, {
      replaceUrl: true,
    });
  }

  public async navigateToDefaultUnProtectedRoute(): Promise<void> {
    await this.navController.navigateBack(environment.configuration.routeToRedirectWhenUnAuthenticated, {
      replaceUrl: true,
    });
  }

  public getRouterParamValue(paramName: string): string {
    return this.activatedRoute.snapshot.queryParams[paramName] || null;
  }

  public getParamMapFromRoute(paramName: string): string {
    return this.activatedRoute.snapshot.paramMap.get(paramName);
  }

  public checkIfParamMapFromRouteExist(paramName: string): boolean {
    return this.activatedRoute.snapshot.paramMap.has(paramName);
  }

  //#endregion

}
