//#region Imports

import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

//#endregion

export interface ModalConfirmProps {
  title: string;
  confirmButtonText: string;
  cancelButtonText: string;
  emphasisOn: 'confirm' | 'cancel';
}

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent {

  constructor(
    private modalController: ModalController,
  ) { }

  //#region Properties

  @Input()
  public props: ModalConfirmProps = {
    title: 'Tem certeza?',
    confirmButtonText: 'Cancelar',
    cancelButtonText: 'Confirmar',
    emphasisOn: 'confirm',
  };

  //#endregion

  //#region Getters And Setters

  public get title(): string {
    return this.props.title;
  }

  public get confirmButtonText(): string {
    return this.props.confirmButtonText;
  }

  public get cancelButtonText(): string {
    return this.props.cancelButtonText;
  }

  public get hasCancelEmphasis(): boolean {
    return this.props.emphasisOn === 'cancel';
  }

  //#endregion

  //#region Methods

  public async cancel(): Promise<void> {
    await this.modalController.dismiss();
  }

  public async confirm(): Promise<void> {
    await this.modalController.dismiss(true);
  }

  //#endregion

}
