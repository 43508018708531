<div class="modal-confirm">
  <h1>{{ title }}</h1>

  <div class="modal-confirm--buttons" [class.emphasis-on-cancel]="hasCancelEmphasis">
    <button
      [class.button--primary]="hasCancelEmphasis"
      [class.button--tertiary]="!hasCancelEmphasis"
      (click)="cancel()">{{ cancelButtonText }}
    </button>
    <button
      [class.button--primary]="!hasCancelEmphasis"
      [class.button--tertiary]="hasCancelEmphasis"
      (click)="confirm()">
      {{ confirmButtonText }}
    </button>
  </div>
</div>
