import { Capacitor } from '@capacitor/core';

/**
 * Converte REM para Pixels
 *
 * * @param rem Quantidade em REM
 */
export function convertRemToPixels(rem: number): number {
  const currentRemInPx = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return rem * currentRemInPx;
}

export function isNullOrUndefined(subject: unknown): boolean {
  return subject === null || subject === undefined;
}

/**
 * @example ....('field.subField', content) -> return content.field.subField
 */
export function getObjectDeepProperty<T>(fieldPath: string, content: T): any {
  const fields = fieldPath.split('.');

  if (fields.length > 3)
    throw new Error('The maximum number of possible fields is three');

  while (fields.length > 0)
    content = content[fields.shift()];

  return content;
}

export function isMobileWeb(): boolean {
  const hasTouchScreen = window.matchMedia('(pointer:coarse)').matches;

  return !Capacitor.isNativePlatform() && hasTouchScreen;
}

export function isPullRefreshDisabled(): boolean {
  const isAndroid = /Android/i.test(navigator.userAgent);

  return isMobileWeb() && isAndroid;
}

export async function enterFullscreenMode(): Promise<void> {
  const document = window.document?.documentElement;

  if (!document)
    return;

  const canGoFullscreen = isMobileWeb() && 'requestFullscreen' in document && typeof document.requestFullscreen === 'function';

  if (!canGoFullscreen)
    return;

  await document.requestFullscreen().catch(() => null);
}

export async function exitFullscreenMode(): Promise<void> {
  if (!window.document.exitFullscreen)
    return;

  await window.document.exitFullscreen().catch(() => null);
}
