//#region Imports

import { AppAssetsInterface } from './app-assets.interface';

//#endregion

export const appAssets: AppAssetsInterface = {
  css: {
    buttonGoToProductsCss: 'button--primary-contrast',
    startPageLogoWidth: '15rem',
    startPageShowLogo: true,
    loginPageLogoWidth: '35%',
  },
  backgrounds: {
    startPage: 'assets/img/bg-introscreen.png',
    loginPage: 'assets/images/coins.png',
    forgotPasswordPage: 'assets/images/purple-gradient-background.jpg',
    forgotPasswordSuccessModal: 'assets/images/purple-gradient-background.jpg',
    registerSuccessModal: 'assets/images/purple-gradient-background.jpg',
    missionSuccessBackground: 'assets/images/purple-gradient-background.jpg',
    missionFailedBackground: 'assets/images/black-background.jpg',
    desktopBackground: 'assets/images/white-label-desktop-background.jpg',
  },
  icons: {
    fullLogo: 'assets/icon/tintim-icon.svg',
    fullLogoIntroscreen: 'assets/icon/tintim-icon.svg',
    secretProductIcon: 'assets/icon/secret-pig.svg',
    tryAgainProductIcon: 'assets/icon/pig-happy.svg',
    nothingProductIcon: 'assets/icon/pig-sad.svg',
    mapMarkers: {
      qrcode: 'assets/img/pin-qrcode.svg',
      quiz: 'assets/img/pin-quiz.svg',
      video: 'assets/img/pin-video.svg',
      share: 'assets/img/pin-share.svg',
      completed: 'assets/img/pin-completed.svg',
      failed: 'assets/img/pin-failed.svg',
    },
  },
  images: {
    fallbackImage: 'assets/images/tintim-fallback-image.jpg',
  },
  animations: {
    pulse: 'assets/lotties/pulse.json',
  },
} as const;
