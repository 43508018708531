//#region Imports

import { Directive, HostListener } from '@angular/core';
import { BackButtonService } from '../services/back-button.service';

//#endregion

@Directive({
  selector: '[backButton]',
})
export class BackButtonDirective {

  //#region Constructor

  constructor(
    private readonly backButtonService: BackButtonService,
  ) { }

  //#endregion

  //#region Public Methods

  @HostListener('click')
  public async onClickHost(): Promise<void> {
    await this.backButtonService.executeBackButtonAction();
  }

  //#endregion

}
