//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ChangePasswordPayload } from '../../models/payloads/change-password.payload';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { ResetPasswordWithTokenPayload } from '../../models/payloads/reset-password-with-token.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { ForgotClientPasswordProxy } from '../../models/proxies/forgot-client-password.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { AsyncResult } from '../../modules/http/models/async-result';
import { HttpService } from '../../modules/http/services/http.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserInteractor {

  //#region Constructor

  constructor(
    private readonly httpService: HttpService,
  ) { }

  //#endregion

  //#region Methods

  public async update(id: number, payload: UpdateUserPayload): Promise<AsyncResult<UserProxy>> {
    const url = environment.api.routes.users.byId.replace('{id}', String(id));
    return await this.httpService.put<UserProxy>(url, payload);
  }

  public async create(payload: CreateUserPayload): Promise<AsyncResult<UserProxy>> {
    const url = environment.api.routes.users.base;
    return await this.httpService.post<UserProxy>(url, payload);
  }

  public async changePassword({ currentPassword, newPassword, confirmPassword }: ChangePasswordPayload): Promise<AsyncResult<void>> {
    const url = environment.api.routes.userPassword.changePassword;

    return await this.httpService.post<void>(url, {
      currentPassword,
      newPassword,
      confirmPassword,
    });
  }

  public async forgotPassword(email: string): Promise<AsyncResult<ForgotClientPasswordProxy>> {
    const url = environment.api.routes.userPassword.forgotClientPassword.replace('{email}', email);

    return await this.httpService.post<ForgotClientPasswordProxy>(url, {});
  }

  public async validateResetPasswordToken(token: string): Promise<AsyncResult<boolean>> {
    const url = environment.api.routes.userPassword.verifyCode.replace('{resetPasswordCode}', token);

    return await this.httpService.get<boolean>(url);
  }

  public async resetPassword({ newPassword, token }: ResetPasswordWithTokenPayload): Promise<AsyncResult<void>> {
    const url = environment.api.routes.userPassword.resetPassword.replace('{resetPasswordCode}', token);

    return await this.httpService.post<void>(url, { newPassword });
  }

  public async deleteMe(): Promise<AsyncResult<void>> {
    const url = environment.api.routes.users.getMe;

    return await this.httpService.delete<void>(url);
  }

  public async validateEmail(email: string): Promise<AsyncResult<boolean>> {
    const url = environment.api.routes.users.validateEmail.replace('{email}', email);
    return await this.httpService.post<boolean>(url, {});
  }

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    const url = environment.api.routes.users.getMe;
    return await this.httpService.get<UserProxy>(url);
  }

  //#endregion
}
