//#region Imports

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserInteractor } from '../../interactors/user/user.interactor';
import { ChangePasswordPayload } from '../../models/payloads/change-password.payload';
import { CreateUserPayload } from '../../models/payloads/create-user.payload';
import { ResetPasswordWithTokenPayload } from '../../models/payloads/reset-password-with-token.payload';
import { UpdateUserPayload } from '../../models/payloads/update-user.payload';
import { ForgotClientPasswordProxy } from '../../models/proxies/forgot-client-password.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { TranslationService } from '../../modules/translation/services/translation/translation.service';
import { isNullOrUndefined } from '../../utils/functions';
import { CustomValidatorsService } from '../custom-validators/custom-validators.service';
import { ErrorService } from '../error/error.service';
import { StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class UserService {

  //#region Constructor

  constructor(
    private readonly interactor: UserInteractor,
    private readonly errorService: ErrorService,
    private readonly translationService: TranslationService,
    private readonly storage: StorageService,
    private readonly customValidatorsService: CustomValidatorsService,
  ) { }

  //#endregion

  //#region Properties

  private readonly userSubject: BehaviorSubject<UserProxy | null> = new BehaviorSubject<UserProxy | null>(null);

  //#endregion

  //#region Methods

  public async getMe(): Promise<UserProxy> {
    const { success, error } = await this.interactor.getMe();

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));

    await this.setUser$(success);
    return success;
  }

  public async getUser(): Promise<UserProxy> {
    const { success, error } = await this.storage.get<UserProxy>(environment.keys.userInformation);

    if (error)
      return;

    return success;
  }

  public getUser$(): Observable<UserProxy> {
    return this.userSubject.asObservable();
  }

  public setUser(user: UserProxy): void {
    this.userSubject.next(user);
  }

  public async setUser$(next: UserProxy): Promise<void> {
    this.userSubject.next(next);
    await this.storage.set(environment.keys.userInformation, next);
  }

  public async refreshUser(): Promise<void> {
    this.userSubject.next(await this.getUser());
  }

  public clearUser(): void {
    this.userSubject.next(null);
  }

  public async shouldKeepConnected(): Promise<boolean> {
    const { success, error } = await this.storage.get<boolean>(environment.keys.keepConnected);

    if (error)
      return false;

    return success;
  }

  public async update(id: number, payload: Partial<UpdateUserPayload>): Promise<UserProxy> {
    if (payload.email?.trim().length && !this.customValidatorsService.isValidEmail(payload.email))
      throw new Error('Email inválido.');

    if (payload.password?.trim().length && !this.customValidatorsService.isValidPassword(payload.password))
      throw new Error('Senha inválida.');

    if (payload.name?.trim().length && !this.customValidatorsService.isValidUserName(payload.name))
      throw new Error('Nome inválido.');

    if (payload.cpf?.trim().length && !this.customValidatorsService.isValidCpf(payload.cpf))
      throw new Error('CPF inválido.');

    if (payload.phone?.trim().length && !this.customValidatorsService.isValidPhone(payload.phone))
      throw new Error('Telefone inválido.');

    if (!isNullOrUndefined(payload.birthday) && !this.customValidatorsService.isValidBirthdate(payload.birthday))
      throw new Error('Data de nascimento inválida.');

    const { success: updatedUser, error } = await this.interactor.update(id, payload);

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));

    return updatedUser;
  }

  public async create(payload: CreateUserPayload): Promise<UserProxy> {
    if (!this.customValidatorsService.isValidEmail(payload.email))
      throw new Error('Email inválido.');

    if (!this.customValidatorsService.isValidPassword(payload.password))
      throw new Error('Senha inválida.');

    if (!this.customValidatorsService.isValidUserName(payload.name))
      throw new Error('Nome inválido.');

    if (payload.phone?.trim().length && !this.customValidatorsService.isValidPhone(payload.phone))
      throw new Error('Telefone inválido.');

    const { success: createdUser, error } = await this.interactor.create(payload);

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));

    return createdUser;
  }

  public async changePassword({ currentPassword, newPassword, confirmPassword }: ChangePasswordPayload): Promise<void> {
    if (!this.customValidatorsService.isValidPassword(newPassword))
      throw new Error('Sua nova senha está inválida.');

    if (!this.customValidatorsService.isValidPassword(confirmPassword))
      throw new Error('Sua confirmação de nova senha está inválida.');

    if (newPassword !== confirmPassword)
      throw new Error('Sua nova senha e a confirmação de nova senha devem ser iguais.');

    const { error } = await this.interactor.changePassword({
      currentPassword,
      newPassword,
      confirmPassword,
    });

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));
  }


  public async forgotPassword(email: string): Promise<ForgotClientPasswordProxy> {
    if (!this.customValidatorsService.isValidEmail(email))
      throw new Error('Email inválido.');

    const { success, error } = await this.interactor.forgotPassword(email);

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));

    return success;
  }

  public async validateResetPasswordToken(token: string): Promise<boolean> {
    const isValid = token.trim().length && token.trim().length === 6;

    if (!isValid)
      throw new Error('Código de verificação inválido.');

    const { success, error } = await this.interactor.validateResetPasswordToken(token);

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));

    return success;
  }

  public async resetPassword({ newPassword, passwordConfirmation, token }: ResetPasswordWithTokenPayload): Promise<void> {
    const isValidToken = token.trim().length && token.trim().length === 6;

    if (!isValidToken)
      throw new Error('Código de verificação inválido.');

    const isValidPassword = this.customValidatorsService.isValidPassword(newPassword);

    if (!isValidPassword)
      throw new Error('Senha inválida.');

    const isValidPasswordConfirmation = this.customValidatorsService.isValidPassword(passwordConfirmation);

    if (!isValidPasswordConfirmation)
      throw new Error('Confirmação de senha inválida.');

    const { error } = await this.interactor.resetPassword({
      newPassword,
      passwordConfirmation,
      token,
    });

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));
  }

  public async deleteMe(): Promise<void> {
    const { error } = await this.interactor.deleteMe();

    if (error)
      throw new Error(this.errorService.getErrorMessage(error));
  }

  public async validateEmail(email: string): Promise<boolean> {
    if (!this.customValidatorsService.isValidEmail(email))
      throw 'E-mail inválido.';

    const { success: isUsedEmail, error } = await this.interactor.validateEmail(email);

    if (error)
      throw this.errorService.getErrorMessage(error);

    if (isUsedEmail)
      throw 'Este e-mail já está sendo usado, tente novamente.';

    return true;
  }

  //#endregion

}
