import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InstallPwaModalComponent } from './install-pwa-modal.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
  ],
  declarations: [
    InstallPwaModalComponent
  ],
  exports: [
    InstallPwaModalComponent
  ],
})
export class InstallPwaModalModule {}
