import { Injectable } from '@angular/core';
import i18next, { i18n, TOptionsBase } from 'i18next';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../services/storage/storage.service';
import { LanguagesEnum, supportedLanguages } from '../../models/languages.enum';
import { TranslationConfiguration } from '../../models/translation-configuration';

type i18nEvents = 'initialized' | 'loaded' | 'failedLoading' | 'missingKey' | 'added' | 'removed' | 'languageChanged';

@Injectable()
export class I18nService {

  //#region Public Properties

  public i18nInstance: BehaviorSubject<i18n> = new BehaviorSubject<i18n>(null);

  //#endregion

  //#region Constructor

  constructor(
    private translationConfiguration: TranslationConfiguration,
    private storageService: StorageService,
  ) {}

  //#endregion

  //#region Public Methods

  public async initializeInstance(callback: () => void): Promise<void> {
    this.i18nInstance.next(i18next);

    const modulesToUse = this.translationConfiguration.use;

    for (const module of modulesToUse)
      this.i18nInstance.getValue().use(module);

    const { success: storedLanguage } = await this.storageService.get<string>(environment.keys.language);

    const options = {
      ...this.translationConfiguration.config,
      ...storedLanguage && { lng: storedLanguage },
    };

    await this.i18nInstance.getValue().init(options, () => callback());
  }


  public getLanguage(): string {
    return this.i18nInstance.getValue().language;
  }

  public translate(translationCode: string, options?: TOptionsBase): any {
    return this.i18nInstance.getValue().t(translationCode, options);
  }

  public isSupportedLanguage(languageToSearch: string): boolean {
    return supportedLanguages.some(language => language === languageToSearch);
  }

  public unsubscribeEvent(event: i18nEvents, handler: (...args: any[]) => void): void {
    return this.i18nInstance.getValue().off(event, handler);
  }

  public subscribeEvent(event: i18nEvents, handler: (...args: any[]) => void): void {
    return this.i18nInstance.getValue().on(event, handler);
  }

  public async changeLanguage(language: LanguagesEnum): Promise<void> {
    await this.i18nInstance.getValue().changeLanguage(language);
  }

  //#endregion

}
