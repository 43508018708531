//#region Imports

import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ModalConfirmComponent, ModalConfirmProps } from '../../../modals/modal-confirm/modal-confirm.component';
import { ModalService } from '../../../services/modals/modal.service';
import { RouterService } from '../../../services/router/router.service';
import { UserService } from '../../../services/user/user.service';
import { isMobileWeb } from '../../../utils/functions';
import { StackActionService } from './stack-action.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class BackButtonService implements OnDestroy {

  //#region Constructor

  constructor(
    private readonly router: Router,
    private readonly platform: Platform,
    private readonly location: Location,
    private readonly userService: UserService,
    private readonly activeRoute: ActivatedRoute,
    private readonly routerService: RouterService,
    private readonly modalService: ModalService,
    private readonly stackActions: StackActionService,
  ) {
  }

  //#endregion

  //#region Private Properties

  private backButtonSubscription: Subscription = null;

  //#endregion

  //#region Public Methods

  public ngOnDestroy(): void {
    this.backButtonSubscription?.unsubscribe();
  }

  public async initializeBackButton(): Promise<void> {
    this.stackActions.initializeActions();

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, async () => {
      await this.executeBackButtonAction();
    });

    if (isMobileWeb()) {
      window.addEventListener('popstate', async () => {
        await this.executeBackButtonAction();
      });
    }
  }

  public async executeBackButtonAction(): Promise<void> {
    const backStackItems = this.stackActions.backStackItems;
    const isNotAtRegister = this.router.url.includes('login') && !this.router.url.includes('register');

    if (this.router.url.includes('start'))
      return void await this.closeApp();

    if ((this.router.url.includes('main/campaigns') && backStackItems.length === 0) || isNotAtRegister)
      return void await this.openModalToCloseTheApp();

    return void await this.navigateBack();
  }

  //#endregion

  //#region Private Methods

  private async navigateBack(): Promise<void> {
    let stackItem = this.stackActions.popBackStackItem();

    if (!stackItem)
      stackItem = { url: 'main/campaigns', type: 'url' };

    switch (stackItem?.type) {
      case 'action':
          await this.navigateBack();
          await stackItem.action();
        break
      case 'url':
          if (stackItem.url?.includes('quiz') || stackItem.url?.includes('qrcode'))
            stackItem = { url: 'main/campaigns', type: 'url' };

          this.stackActions.blockToAddUrl = true;
          await this.routerService.navigateBackwards(stackItem.url, { animated: true, animationDirection: 'back' });
          this.stackActions.blockToAddUrl = false;
        break
    }
  }

  private async openModalToCloseTheApp(): Promise<void> {
    const props: ModalConfirmProps = {
      title: 'Deseja fechar o app?',
      confirmButtonText: 'Fechar',
      cancelButtonText: 'Cancelar',
      emphasisOn: 'cancel',
    };

    const modal = await this.modalService.create({
      component: ModalConfirmComponent,
      cssClass: 'confirm-modal',
      componentProps: { props },
    });

    await modal.present();

    const { data: exitApp } = await modal.onDidDismiss();

    if (!exitApp)
      return;

    await this.closeApp();
  };

  private async closeApp(): Promise<void> {
    await navigator['app'].exitApp();
  }

  //#endregion

}
