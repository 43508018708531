export function convertValueToDate(value: string): Date {
  if (!value)
    return;

  const [day, month, year] = value.split('/');

  if (!year)
    return;

  return new Date(`${month}/${day}/${year}`);
}
