//#region Imports

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

import { Observable, Subject } from 'rxjs';
import { AlertService } from '../../../services/alert/alert.service';

import { AsyncResult } from '../models/async-result';
import { DefaultOptions, ExtraOptions } from '../models/http-options';

//#endregion

@Injectable()
export class HttpService {

  //#region Constructor

  constructor(
    protected readonly http: HttpClient,
    protected readonly alertService: AlertService,
  ) { }

  //#endregion

  //#region Properties

  private readonly onHttpError: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

  //#endregion

  //#region Methods

  public getOnHttpError$(): Observable<HttpErrorResponse> {
    return this.onHttpError.asObservable();
  }

  public getHttpClient(): HttpClient {
    return this.http;
  }

  public getNativeClient(): HttpClient {
    return this.http;
  }

  public async get<T>(
    url: string,
    options?: DefaultOptions,
  ): Promise<AsyncResult<T>> {
    try {
      await this.validateConnection();
    } catch (e) {
      const error = new HttpErrorResponse({ error: new Error(e.message), status: 503 });
      return this.error<T>(error);
    }

    return await this.http.get<T>(url, options).toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async post<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    try {
      await this.validateConnection();
    } catch (e) {
      const error = new HttpErrorResponse({ error: new Error(e.message), status: 503 });
      return this.error<T>(error);
    }

    return await this.http.post<T>(url, payload, options).toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async put<T>(
    url: string,
    payload: object,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    try {
      await this.validateConnection();
    } catch (e) {
      const error = new HttpErrorResponse({ error: new Error(e.message), status: 503 });
      return this.error<T>(error);
    }

    return await this.http.put<T>(url, payload, options).toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async delete<T>(
    url: string,
    options?: ExtraOptions,
  ): Promise<AsyncResult<T>> {
    try {
      await this.validateConnection();
    } catch (e) {
      const error = new HttpErrorResponse({ error: new Error(e.message), status: 503 });
      return this.error<T>(error);
    }

    return await this.http.delete<T>(url, options).toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  private async validateConnection(): Promise<boolean> {
    const status = await Network.getStatus();

    if (!status.connected)
      throw new Error('Você não está conectado a internet.');

    return status.connected;
  }

  private success<T>(result: T): AsyncResult<T> {
    return {
      success: result,
    };
  }

  private error<T>(error: HttpErrorResponse): AsyncResult<T> {
    this.onHttpError.next(error);

    return {
      error,
    };
  }

  //#endregion

}
