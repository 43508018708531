<div class="modal">
  <h1>Adicionar app a tela inicial do dispositivo?</h1>

  <div class="buttons-container">
    <button class="button--primary" (click)="addToHomeScreen()">
      Instalar
    </button>

    <button class="button--tertiary" (click)="closeModal('dismissed')">
      Cancelar
    </button>
  </div>
</div>
